@import './style/index.less';

.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}

.App {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  .ant-layout {
    background: #100f0f;
  }

  .ant-layout-header,
  .ant-layout-header .ant-menu.ant-menu-dark {
    background-color: #000;
    //background-color: #317cec;
  }

  .ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
    background-color: #3aff5f;
    color: #000;

    .header-item {
      color: #000;
    }

  }

  .ant-layout-sider-light {
    border-top: 8px solid @background;
  }
 
  .ant-layout-header {
    .ant-menu.ant-menu-dark {
      >li {
        color: #fff;
      }

      .ant-menu-item-selected {
        //background-color: #1460d2;
        background-color: #3aff5f;

        .header-item {
          color: #000;
        }
      }
    }
  }

  .ka-wrapper {
    height: 100%;

    .ka-content {
      height: 100%;
    }
  }

  .tx-content {
    //padding: 8px;
    border-left: 1px solid #eaeaea;
    box-sizing: border-box;
    overflow-x: inherit !important;
  }

  .app-content {
    flex-grow: 1;
    height: 0;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.ant-picker-cell-in-view {
  &.ant-picker-cell-range-start.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      background: #1890ff !important;
    }
  }

  &.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      background: #ff7a45 !important;
    }
  }
}

.amap-logo,
.amap-copyright {
  display: none !important;
}

.table-tree {
  width: 230px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 12px;

  .table-tree-query {
    >span {
      border-radius: 0;
    }
  }

  .table-treecontent {
    flex: 1;
    border: 1px solid #dfdfdf;
    border-top: none;
    overflow-y: auto;
  }
}

.map-crosshair {
  cursor: crosshair !important;
}


// 魔法
//.ant-layout-sider-children {
//  background-color: #000;
//}
//.App .ant-layout-sider-light {
//  border: 0;
//}
//
//.ant-menu{
//  background-color: #000;
//  color: #fff;
//}
//.ant-menu-inline {
//  border:0;
//}
//.ant-menu-item a {
//  color: #fff;
//}
//.ant-menu-sub.ant-menu-inline {
//  background-color: #000;
//}
//.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//  background-color: #373737;
//}
//.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
//  border-right: 3px solid #3aff5f;
//}
//.ant-menu-submenu-selected {
//  color: #3aff5f;
//}
//.ant-menu-item a:hover {
//  color: #3aff5f;
//}
//.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
//  color: #3aff5f;
//}
//.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
//  background: #3aff5f;
//}
@fontsizeColor: #FFFFFF;
@bgcolor2: #3AFF5F;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}

.App .ant-layout-sider-light {
  border: 0;
}

.col-pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: @fontsizeColor;

  &.ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: @bgcolor2;
    border: none
  }

  & .ant-pagination-prev button,
  .ant-pagination-next button {
    color: @fontsizeColor
  }

}

//修复button在loading状态的时候，转圈显示异常
.ant-btn-loading-icon {
  padding-right: 8px;
}

.ant-btn>.ant-btn-loading-icon .anticon {
  padding-right: 0;
}