@optionSize: -2px;

.tx-large {
  height: 100vh;
  color: #fff;
  box-sizing: border-box;
  background-color: #060A36;
  background-image: url("/images/large_screen/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1 !important;

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
  }

  .tx-large-vehicle-total {
    height: 27%;
  }

  .large-deduted-content,
  .large-distri-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .large-deduted-content .large-deduted-echarts,
  .large-distri-content .large-distri-echarts {
    flex-grow: 9;
  }

  .large-deduted-echarts {
    .large-deduted-echarts-one {
      position: relative;
      width: 100%;
      height: 45%;
      margin-bottom: 3%;

      .deduted-items {
        width: 40%;
        position: absolute;
        top: 0;
        right: 0;
        padding-left: 4px;
        padding-top: 6px;

        .deduted-item {
          position: relative;
          padding-left: 14px;
          box-sizing: border-box;
          margin-top: 2px;
          word-break: break-all;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          &::after {
            content: ' ';
            position: absolute;
            width: 10px;
            height: 4px;
            top: 8px;
            left: 1px;
            border-radius: 2px;
            background-color: #1393EF;
          }

          &:nth-child(2)::after {
            background-color: #0DD2AF;
          }

          &:nth-child(3)::after {
            background-color: rgb(216, 226, 59);
          }

          &:nth-child(4)::after {
            background-color: rgb(238, 160, 98);
          }

          &:nth-child(5)::after {
            background-color: #D3555C;
          }
        }

      }
    }
  }

  .large-vehicle-content {
    .order-number {
      width: 0.21rem;
      height: 0.21rem;
      border-radius: 50%;
      background-color: #f7a562;
      color: #06062a;
      text-align: center;
      line-height: 0.2rem;
    }

    .large-vehicle-table {
      background: transparent;
      margin-top: 0.14rem;

      .ant-table,
      .ant-table-thead > tr > th {
        background: transparent;
      }

      .ant-table-container,
      .ant-table table,
      .ant-table-container table > thead > tr:first-child th:first-child,
      .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0;
      }

      .ant-table-thead > tr > th {
        color: #1398f5;
        border-bottom: none;
      }

      .ant-table-placeholder {
        display: none;
      }

      thead {
        background: linear-gradient(90deg,
        rgba(40, 40, 112, 1) 0%,
        rgba(40, 40, 112, 1) 100%);
      }

      .large-vehicle-td {
        &:nth-child(even) {
          background: linear-gradient(90deg,
          rgba(40, 40, 112, 0.3) 0%,
          rgba(40, 40, 112, 0.3) 100%);
        }

        & > td:nth-child(1) {
          color: #eee;
        }

        & > td:nth-child(2) {
          color: #eee;
        }

        > td {
          color: rgba(#1398f5, 0.5);
          border-bottom: none;
        }

        &:hover > td {
          background-color: transparent;
        }
      }
    }
  }

  .top-title {
    display: flex;
    align-items: baseline;
    width: 100%;

    .top-title-name {
      margin-right: 0.06rem;
      padding-top: 13px;
    }
  }

  .tx-large-title {
    padding: 0.03rem 0.12rem;
    font-size: 24px;
    font-weight: 400;
    flex: 1;
    background: linear-gradient(90deg,
    rgba(39, 39, 112, 0.9) 0%,
    rgba(0, 0, 0, 0) 80%);
  }

  .tx-large-left {
    height: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    margin-top: 50px;
    z-index: 999;

    > div:not(:last-child) {
      margin-bottom: 0.12rem;
    }

    .tx-large-deduted {
      flex: 1;
    }
  }

  .tx-large-right {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .large-main-title {
      font-size: 20px;
      position: absolute;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      z-index: 999;

      div {
        margin-right: 160px;
      }

      div:nth-of-type(4) {
        margin-right: 0;
      }


      .large-main-title-left {
        margin-right: 33%;
      }

      span {
        color: #ffa6ed;
        font-size: 32px;
        display: inline-block;
        margin-bottom: 20px;
      }

      .large-main-title {

      }

      .large-main-title.top {
        margin-bottom: 30px;

        .large-main-title-left {
          margin-right: 150px;
        }
      }
    }
  }

  .large-right-top {
    height: 60px;
    // padding: 0 0 0.1rem 0.1rem;
    display: flex;
    position: absolute;
    z-index: 2;
    right: 25px;
    top: 4px;
    align-items: center;

    .large-right-top-title {
      flex: 1;
      display: flex;
      justify-content: space-between;
      font-size: 35px;
      height: 100%;

      .top-title-icon {
        height: 100%;
        width: 1.7rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      .left-icon {
        // background-image: url(/images/large_screen/left-line.png);
      }

      .right-icon {
        // background-image: url(/images/large_screen/right-line.png);
      }

      .top-title-name {
        display: flex;
        align-items: center;
        letter-spacing: 30px;
      }
    }

    .large-right-top-time {
      display: flex;
      font-size: 22px;
      justify-content: center;

      .time-item {
        height: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        &:not(:last-child) {
          margin-right: 3px;
        }

        &:nth-child(10) {
          margin-right: .14rem;
        }
      }

      .time-mRight {
        margin-right: 10px;
      }
    }
  }

  .large-right-bottom {
    display: flex;
    flex-grow: 1;

    .large-right-bottom-left {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: 100%;
      width: 0;

      > div:not(:last-child) {
        margin-bottom: 0.12rem;
      }

    }

    .tx-large-map {
      flex: 1;

      .large-map-content {
        height: 100%;
        position: relative;
      }
    }

    .tx-large-video {
      height: 30%;

      .large-video-content {
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        > div {
          width: 20%;
          height: 100%;
        }
      }
    }

    .large-right-bottom-right {
      width: 4.2rem;
      display: flex;
      flex-direction: column;

      > div:not(:last-child) {
        margin-bottom: 0.12rem;
      }

      .tx-large-distri {
        height: 40%;
      }

      .tx-large-vehicle {
        flex: 1;
      }
    }
  }

  .large-content-total {
    display: flex;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.15rem;
    }

    .content {
      .content-value {
        font-size: 26px;
        font-weight: 400;
      }

      .content-title {
        margin-top: .08rem;
        opacity: .5;
      }
    }
  }

  .tx-chunk {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid rgba(#eee, 0.25);
    padding: 4px;

    .top-left-angle,
    .top-right-angle,
    .bottom-left-angle,
    .bottom-right-angle {
      position: absolute;
      border: 3px solid #1398f5;
      z-index: 2;
      pointer-events: none;
    }

    .top-left-angle {
      top: @optionSize;
      left: @optionSize;
      border-right: transparent;
      border-bottom: none;
    }

    .top-right-angle {
      top: @optionSize;
      right: @optionSize;
      border-left: transparent;
      border-bottom: none;
    }

    .bottom-left-angle {
      bottom: @optionSize;
      left: @optionSize;
      border-right: transparent;
      border-top: none;
    }

    .bottom-right-angle {
      bottom: @optionSize;
      right: @optionSize;
      border-left: transparent;
      border-top: none;
    }
  }

  .large-alarm-total-content,
  .large-vehicle-total {
    padding: 0.12rem 0.15rem;
  }

  .large-alarm-total-content .arge-alarm-total-data,
  .large-vehicle-total .content-total-data {
    display: flex;
    margin-top: .16rem;
  }

  .large-vehicle-total {

    .content-total-item {
      width: 50%;
      padding: 0 0.2rem;
      text-align: center;

      & .item-progress,
      & .item-title {
        margin-left: 8px;
      }

      & .item-progress,
      & .item-title {
        margin-top: 20px;
      }

      .item-progress {

        .ant-progress-inner {
          height: 70px !important;
          width: 70px !important;
        }
      }

    }
  }

  .item-progress-value {
    color: #fff;
    font-size: 16px;

    > span {
      color: #F7A562;
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .tx-large {
    .large-vehicle-content {
      .order-number {
        width: 0.19rem;
        height: 0.19rem;
        line-height: 0.18rem;
        font-size: 14px;
        margin-left: 10px;
      }

      .large-vehicle-table {

        .ant-table tr > td,
        .ant-table tr > th {
          padding: 4px;
        }
      }
    }

    .tx-large-vehicle-total {
      height: 33%;
    }

    .content-total-item {
      padding: 0 0.15rem;
    }

    .large-vehicle-total {
      .content-total-item {
        padding: 0 0.15rem;

        & .item-progress,
        & .item-title {
          margin-left: 18px;
        }
      }
    }

    .large-alarm-total-content,
    .large-vehicle-total {
      padding: 0.1rem 0.1rem;
    }

    .large-alarm-total-content .arge-alarm-total-data,
    .large-vehicle-total .content-total-data {
      display: flex;
      margin-top: 10px;
    }

    .tx-large-title {
      font-size: 20px;
    }

    .large-right-top {
      height: .7rem;

      .large-right-top-title {
        font-size: 28px;

        .top-title-icon {
          width: 0.85rem;
        }

        .top-title-name {
          letter-spacing: 14px;
        }
      }

      .large-right-top-time {
        font-size: 20px;
        width: 4rem;
      }
    }

    .large-right-bottom {

      .large-right-bottom-right {
        width: 3.5rem;
      }
    }

    .large-deduted-echarts {
      .large-deduted-echarts-one {
        .deduted-items {
          padding-top: 0;

          .deduted-item {

            margin-top: 0;

            &:not(:last-child) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.BMapLib_sendToPhone {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade1 {
  animation: fadeIn 2s;
  animation-fill-mode: backwards;
}
.fade2{
  animation: fadeOut 2s;
  animation-fill-mode: backwards;
}
