// 正文颜色
@textcolor1: #222222;
// 二级文字
@textcolor2: #4a4a4a;
// 提示信息文字
@tip: #959595;
// 边框分割线
@hakvingline: #d9d9d9;
// 禁用
@disablecolor: #ebebeb;
// 背景色
@background: #f0f2f5;
// 表格头部色
@header: #f2f5f7;

// 一级导航图标
@iconSize1: 18px;
// 二级导航图标
@icocSize2: 16px;
// 其它图标
@iconSize3: 14px;

/** 按钮色 */
@btnHoverColor: #307cec;

/** 按钮按下去的颜色 */
@btnActiveColor: #1360d1;

/** 禁用 */
@disable: #ebebeb;

/** 禁止选中文本 */
.disable-select {
  user-select: none
}
